.site-footer {
  background-color: #03121e;
  text-align: center;
}

.site-footer .footer-nav,
.site-footer .footer-nav-line-2 {
  font-family: 'Open Sans', Arial;
  color: white;
  font-size: 0.9rem;
  line-height: 3;
}

.site-footer .footer-nav-line-2 {
  margin-top: -20px;
}

.site-footer .footer-nav a,
.site-footer .footer-nav-line-2 a {
  color: #fff;
}

.site-footer .footer-nav a:hover,
.site-footer .footer-nav-line-2 a:hover {
  color: #f50856;
}

.site-footer .footer-legal {
  color: #fff;
  font-size: 0.9rem;
}

.site-footer .footer-info {
  color: #999;
  font-size: 0.75rem;
}
