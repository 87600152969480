.user-icon {
  background: none;
  border: none;
  position: absolute;
  width: 2.3rem;
  height: auto;
  right: 0px;
  bottom: 30px;
  z-index: 101;
  cursor: pointer;
  outline: none;
}

.signin-button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  position: absolute;
  text-align: center;
  width: 4rem;
  right: 10px;
  bottom: 10px;
}

.signout-button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  position: absolute;
  text-align: center;
  width: 4rem;
  right: 5px;
  bottom: 10px;
}

.sandbox-button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  position: absolute;
  text-align: center;
  width: 4rem;
  right: 5rem;
  bottom: 10px;
}

.presentation-button {
  background: #000;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  position: absolute;
  text-align: center;
  width: 4rem;
  right: 10px;
  top: auto;
  margin-top: -50px;
}

.form-button {
  background: #000;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  margin: 0.5rem;
  text-align: center;
  width: 4rem;
}

.cancel-button {
  background: #fff;
  border: 1px solid #ff439a;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  margin: 0.5rem;
  text-align: center;
  width: 4rem;
}

.submit-button {
  background: #000;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  color: #ff439a;
  font-size: 12px;
  opacity: 1;
  padding: 0.1rem 0.5rem;
  text-transform: none;
  text-align: center;
}

.submit-disabled-button {
  background-color: #d3d3d3;
  color: #888888;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .user-icon {
    width: 2rem;
    height: 1.3rem;
  }
  .signout-button {
    right: 5px;
    font-size: 10px;
    width: 3.5rem;
    padding: 0.01rem 0.5rem;
  }
  .signin-button {
    right: 5px;
    font-size: 10px;
    width: 3.5rem;
    padding: 0.01rem 0.5rem;
  }
  .sandbox-button {
    right: 65px;
    font-size: 10px;
    width: 3.5rem;
    padding: 0.01rem 0.5rem;
  }
  .presentation-button {
    right: 5px;
    font-size: 10px;
    width: 3.5rem;
    margin-top: -25px;
  }
  .form-button,
  .cancel-button,
  .submit-button {
    font-size: 10px;
  }
}

.signout-button:hover,
.signin-button:hover,
.cancel-button:hover {
  color: black;
}

.presentation-button:hover,
.form-button:hover,
.submit-button:hover {
  color: white;
}

.submit-disabled-button:hover {
  color: #888888;
}

a:hover {
  color: #983c80;
}

.site-header {
  background-color: #000;
  padding-bottom: 20%;
  margin: 0;
  position: relative;
  height: 300px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .site-header {
    padding-bottom: 40%;
  }
}

.responsive-banner {
  @media (min-width: 768px) {
    /* For desktops */
    object-position: center 70%;
  }

  @media (max-width: 767px) {
    /* For phones */
    object-position: bottom;
  }
}

#headerVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: 'none';
}

.logo {
  position: absolute;
  left: 10px;
  top: 0px;
  height: auto;
  width: 50px;
}

@media (max-width: 768px) {
  .logo {
    left: 5px;
    top: 0px;
    width: 40px;
  }
}

.user-label {
  position: absolute;
  left: 0px;
  bottom: 10px;
  color: #ff439a;
  font-size: 11px;
  font-family: arial;
  background: #fff;
  padding-left: 10px;
  padding-right: 5px;
}

.sandbox-label {
  position: absolute;
  left: 0px;
  bottom: 40px;
  color: #ff439a;
  font-size: 11px;
  font-family: arial;
  background: #fff;
  padding-left: 10px;
  padding-right: 5px;
}

@media (max-width: 768px) {
  .user-label {
    bottom: 10px;
    font-size: 10px;
  }
  .sandbox-label {
    bottom: 30px;
    font-size: 10px;
  }
}

.code-block {
  position: relative;
  margin-top: 25px;
  margin-bottom: 0px;
  overflow: visible;
  width: 80%;
}

.code-block .copy-button {
  border: 1px solid transparent;
  color: #ff439a;
  font-size: 11px;
  opacity: 1;
  text-transform: none;
  position: absolute;
  text-align: center;
  padding: 0px;
  width: 35px;
  right: 0px;
  top: -20px;
}

.code-block .copy-button:hover {
  color: #000;
}

.tooltip {
  position: absolute;
  top: 0px;
  /* Adjust this as necessary */
  right: 50px;
  /* Position the tooltip to the left of the button */
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.75em;
  z-index: 100;
  /* Make sure it appears above other content */
  opacity: 0.75;
  transition: opacity 0.3s;
}

.code-block .copy-button:hover + .tooltip {
  opacity: 1;
  /* Ensure the tooltip is fully visible when hovering over the button */
}

.challenge-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.challenge-item,
.total-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.total-item {
  font-size: large;
}

.challenge-title,
.challenge-stats,
.challenge-action {
  flex-basis: 100%; /* Default to full width on small screens */
  margin-bottom: 10px;
}

.challenge-title {
  font-size: large;
  font-weight: bold;
}

.challenge-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align text to the right */
}

.challenge-stats > span {
  margin-bottom: 5px;
}

.challenge-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.challenge-action input {
  width: 100px;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .challenge-title,
  .challenge-stats,
  .challenge-action {
    flex-basis: calc(33.333% - 20px); /* Three equal columns with some margin */
    margin-bottom: 0;
  }

  .challenge-stats {
    flex-direction: column; /* Keep column layout for stats on larger screens */
    align-items: flex-end;
  }

  .challenge-stats > span {
    margin-bottom: 5px;
  }
}
