.profile-container {
  position: absolute;
  bottom: 29px;
  right: 25px;
  z-index: 100;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .profile-container {
    bottom: 22px;
    right: 22px;
    font-size: 10px;
  }
}

.menu-item:hover {
  color: #000;
}
