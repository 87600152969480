.menu-container,
.menu-container-line-2 {
  position: absolute;
  top: 10px;
  right: 0px;
  z-index: 100;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}

.menu-container-line-2 {
  top: 40px;
}

.hamburger {
  position: absolute;
  top: 0px;
  right: 5px;
  z-index: 100;
  display: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-family: 'monospace';
}

@media (max-width: 768px) {
  .menu-container,
  .menu-container-line-2 {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .hamburger {
    display: block;
  }

  .hamburger.active {
    color: #ff439a;
  }
  .hamburger.active:hover {
    color: darkred;
  }
  .hamburger:hover {
    color: #ff439a;
  }
  .menu-container.active,
  .menu-container-line-2.active {
    display: block;
    top: 12px;
    right: 35px;
    font-size: 10px;
    width: 190px;
  }

  .menu-container-line-2.active {
    top: 35px;
    width: 130px;
  }
}
