.home-story {
  background-color: white;
  color: black;
  padding: 5rem;
  padding-top: 3rem;
  text-align: center;
  align-items: center;
  font-size: medium;
}

@media (max-width: 767px) {
  .home-story {
    padding: 1rem;
    align-items: center;
  }
}

.home-story .section-title {
  text-align: center;
  padding-bottom: 50px;
}

.home-story .signature {
  font-weight: bold;
}

.home-story .content-table td {
  cursor: pointer;
  vertical-align: top;
  background: lightgray;
  width: auto;
}

.training-story,
.blog-story,
.news-story {
  text-align: left;
}

.training-tile-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.training-category {
  margin-bottom: 2rem;
  width: 100%;
}

.training-tile-subcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  width: 100%;
}

.training-tile {
  text-align: center;
  max-width: 100px;
  background: rgb(237, 224, 216);
  margin: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .training-tile {
    max-width: 70px;
  }
}

.training-tile img {
  width: 100%;
  height: auto;
}

.training-tile .subtext {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: bolder;
  margin-top: auto;
  max-width: 100px;
  color: #102e44;
}

@media (max-width: 767px) {
  .training-tile .subtext {
    font-size: 10px;
  }
}

.training-story a,
.blog-story a,
.news-story a {
  color: #ff439a;
}

.training-story code,
.blog-story code,
.news-story code {
  color: #ff439a;
  font-family: 'Courier New', Courier, monospace;
}

.training-story .gray-field,
.blog-story .gray-field,
.news-story .gray-field {
  max-width: 600px;
  background: lightgray;
  color: black;
  flex-direction: column;
  min-height: 30px;
  overflow: auto;
}

.training-story .darkgray-field,
.blog-story .darkgray-field,
.news-story .darkgray-field {
  max-width: 600px;
  background: darkgray;
  color: black;
}

.training-story .lightyellow-field,
.blog-story .lightyellow-field,
.news-story .lightyellow-field {
  max-width: 600px;
  background: lightyellow;
  color: black;
}

.training-story .diagram,
.blog-story .diagram,
.news-story .diagram {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.training-story .diagram img,
.blog-story .diagram img,
.news-story .diagram img {
  background: white;
  justify-content: center;
  align-items: center;
}

.sections-container {
  padding-left: 20%;
  padding-right: 20%;
  display: grid;
}

@media (max-width: 768px) {
  .sections-container {
    padding-left: 5%;
    padding-right: 5%;
    grid-template-columns: 1fr;
  }
}

.section {
  padding: 20px;
  padding-bottom: 100px;
  background-color: #fff;
  place-items: center;
  display: grid;
}

.section-cert-icons {
  padding: 20px;
  padding-bottom: 100px;
  background-color: #fff;
  place-items: center;
}

.section-table {
  width: fit-content;
}

.home-image {
  width: 150px;
}
