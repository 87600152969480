.row {
  max-width: 96.875rem;
  margin-left: auto;
  margin-right: auto;
}

.row:after,
.row:before {
  content: ' ';
  display: table;
}

.row:after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row .row {
  max-width: none;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

@media screen and (min-width: 40em) {
  .row .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
}

.row .row.collapse {
  margin-left: 0;
  margin-right: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-left: auto;
  margin-right: auto;
}

.column,
.columns {
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.column.about_column {
  float: left;
  padding-left: 20%;
  padding-right: 0.625rem;
}

@media (max-width: 767px) {
  .column,
  .columns,
  .column.about_column {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: right;
}

.column.end:last-child:last-child,
.end.columns:last-child:last-child {
  float: left;
}

.column.row.row,
.row.row.columns {
  float: none;
}

.row .column.row.row,
.row .row.row.columns {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
