.small-1 {
  width: 8.3333333333%;
}

.small-push-1 {
  position: relative;
  left: 8.3333333333%;
}

.small-pull-1 {
  position: relative;
  left: -8.3333333333%;
}

.small-offset-0 {
  margin-left: 0;
}

.small-2 {
  width: 16.6666666667%;
}

.small-push-2 {
  position: relative;
  left: 16.6666666667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  width: 33.3333333333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333333333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6666666667%;
}

.small-push-5 {
  position: relative;
  left: 41.6666666667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  width: 58.3333333333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333333333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6666666667%;
}

.small-push-8 {
  position: relative;
  left: 66.6666666667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  width: 83.3333333333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333333333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6666666667%;
}

.small-push-11 {
  position: relative;
  left: 91.6666666667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 > .column,
.small-up-1 > .columns {
  width: 100%;
  float: left;
}

.small-up-1 > .column:nth-of-type(1n),
.small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-1 > .column:nth-of-type(1n + 1),
.small-up-1 > .columns:nth-of-type(1n + 1) {
  clear: both;
}

.small-up-1 > .column:last-child,
.small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column,
.small-up-2 > .columns {
  width: 50%;
  float: left;
}

.small-up-2 > .column:nth-of-type(1n),
.small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-2 > .column:nth-of-type(2n + 1),
.small-up-2 > .columns:nth-of-type(2n + 1) {
  clear: both;
}

.small-up-2 > .column:last-child,
.small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column,
.small-up-3 > .columns {
  width: 33.3333333333%;
  float: left;
}

.small-up-3 > .column:nth-of-type(1n),
.small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-3 > .column:nth-of-type(3n + 1),
.small-up-3 > .columns:nth-of-type(3n + 1) {
  clear: both;
}

.small-up-3 > .column:last-child,
.small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column,
.small-up-4 > .columns {
  width: 25%;
  float: left;
}

.small-up-4 > .column:nth-of-type(1n),
.small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-4 > .column:nth-of-type(4n + 1),
.small-up-4 > .columns:nth-of-type(4n + 1) {
  clear: both;
}

.small-up-4 > .column:last-child,
.small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column,
.small-up-5 > .columns {
  width: 20%;
  float: left;
}

.small-up-5 > .column:nth-of-type(1n),
.small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-5 > .column:nth-of-type(5n + 1),
.small-up-5 > .columns:nth-of-type(5n + 1) {
  clear: both;
}

.small-up-5 > .column:last-child,
.small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column,
.small-up-6 > .columns {
  width: 16.6666666667%;
  float: left;
}

.small-up-6 > .column:nth-of-type(1n),
.small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-6 > .column:nth-of-type(6n + 1),
.small-up-6 > .columns:nth-of-type(6n + 1) {
  clear: both;
}

.small-up-6 > .column:last-child,
.small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column,
.small-up-7 > .columns {
  width: 14.2857142857%;
  float: left;
}

.small-up-7 > .column:nth-of-type(1n),
.small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-7 > .column:nth-of-type(7n + 1),
.small-up-7 > .columns:nth-of-type(7n + 1) {
  clear: both;
}

.small-up-7 > .column:last-child,
.small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column,
.small-up-8 > .columns {
  width: 12.5%;
  float: left;
}

.small-up-8 > .column:nth-of-type(1n),
.small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}

.small-up-8 > .column:nth-of-type(8n + 1),
.small-up-8 > .columns:nth-of-type(8n + 1) {
  clear: both;
}

.small-up-8 > .column:last-child,
.small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column,
.small-collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.expanded.row .small-collapse.row,
.small-collapse .row {
  margin-left: 0;
  margin-right: 0;
}

.small-uncollapse > .column,
.small-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.small-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.small-pull-0,
.small-push-0,
.small-uncentered {
  position: static;
  margin-left: 0;
  margin-right: 0;
  float: left;
}

@media screen and (min-width: 40em) {
  .medium-1 {
    width: 8.3333333333%;
  }

  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .medium-offset-0 {
    margin-left: 0;
  }

  .medium-2 {
    width: 16.6666666667%;
  }

  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    left: -25%;
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .medium-4 {
    width: 33.3333333333%;
  }

  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    width: 41.6666666667%;
  }

  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    left: -50%;
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .medium-7 {
    width: 58.3333333333%;
  }

  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    width: 66.6666666667%;
  }

  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    left: -75%;
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .medium-10 {
    width: 83.3333333333%;
  }

  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    width: 91.6666666667%;
  }

  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .medium-up-1 > .column,
  .medium-up-1 > .columns {
    width: 100%;
    float: left;
  }

  .medium-up-1 > .column:nth-of-type(1n),
  .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-1 > .column:nth-of-type(1n + 1),
  .medium-up-1 > .columns:nth-of-type(1n + 1) {
    clear: both;
  }

  .medium-up-1 > .column:last-child,
  .medium-up-1 > .columns:last-child {
    float: left;
  }

  .medium-up-2 > .column,
  .medium-up-2 > .columns {
    width: 50%;
    float: left;
  }

  .medium-up-2 > .column:nth-of-type(1n),
  .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-2 > .column:nth-of-type(2n + 1),
  .medium-up-2 > .columns:nth-of-type(2n + 1) {
    clear: both;
  }

  .medium-up-2 > .column:last-child,
  .medium-up-2 > .columns:last-child {
    float: left;
  }

  .medium-up-3 > .column,
  .medium-up-3 > .columns {
    width: 33.3333333333%;
    float: left;
  }

  .medium-up-3 > .column:nth-of-type(1n),
  .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-3 > .column:nth-of-type(3n + 1),
  .medium-up-3 > .columns:nth-of-type(3n + 1) {
    clear: both;
  }

  .medium-up-3 > .column:last-child,
  .medium-up-3 > .columns:last-child {
    float: left;
  }

  .medium-up-4 > .column,
  .medium-up-4 > .columns {
    width: 25%;
    float: left;
  }

  .medium-up-4 > .column:nth-of-type(1n),
  .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-4 > .column:nth-of-type(4n + 1),
  .medium-up-4 > .columns:nth-of-type(4n + 1) {
    clear: both;
  }

  .medium-up-4 > .column:last-child,
  .medium-up-4 > .columns:last-child {
    float: left;
  }

  .medium-up-5 > .column,
  .medium-up-5 > .columns {
    width: 20%;
    float: left;
  }

  .medium-up-5 > .column:nth-of-type(1n),
  .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-5 > .column:nth-of-type(5n + 1),
  .medium-up-5 > .columns:nth-of-type(5n + 1) {
    clear: both;
  }

  .medium-up-5 > .column:last-child,
  .medium-up-5 > .columns:last-child {
    float: left;
  }

  .medium-up-6 > .column,
  .medium-up-6 > .columns {
    width: 16.6666666667%;
    float: left;
  }

  .medium-up-6 > .column:nth-of-type(1n),
  .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-6 > .column:nth-of-type(6n + 1),
  .medium-up-6 > .columns:nth-of-type(6n + 1) {
    clear: both;
  }

  .medium-up-6 > .column:last-child,
  .medium-up-6 > .columns:last-child {
    float: left;
  }

  .medium-up-7 > .column,
  .medium-up-7 > .columns {
    width: 14.2857142857%;
    float: left;
  }

  .medium-up-7 > .column:nth-of-type(1n),
  .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-7 > .column:nth-of-type(7n + 1),
  .medium-up-7 > .columns:nth-of-type(7n + 1) {
    clear: both;
  }

  .medium-up-7 > .column:last-child,
  .medium-up-7 > .columns:last-child {
    float: left;
  }

  .medium-up-8 > .column,
  .medium-up-8 > .columns {
    width: 12.5%;
    float: left;
  }

  .medium-up-8 > .column:nth-of-type(1n),
  .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .medium-up-8 > .column:nth-of-type(8n + 1),
  .medium-up-8 > .columns:nth-of-type(8n + 1) {
    clear: both;
  }

  .medium-up-8 > .column:last-child,
  .medium-up-8 > .columns:last-child {
    float: left;
  }

  .medium-collapse > .column,
  .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .expanded.row .medium-collapse.row,
  .medium-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }

  .medium-uncollapse > .column,
  .medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .medium-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .medium-pull-0,
  .medium-push-0,
  .medium-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}

@media screen and (min-width: 64em) {
  .large-1 {
    width: 8.3333333333%;
  }

  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .large-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .large-offset-0 {
    margin-left: 0;
  }

  .large-2 {
    width: 16.6666666667%;
  }

  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .large-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .large-3 {
    width: 25%;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    left: -25%;
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .large-4 {
    width: 33.3333333333%;
  }

  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .large-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    width: 41.6666666667%;
  }

  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .large-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .large-6 {
    width: 50%;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    left: -50%;
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .large-7 {
    width: 58.3333333333%;
  }

  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .large-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    width: 66.6666666667%;
  }

  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .large-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .large-9 {
    width: 75%;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    left: -75%;
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .large-10 {
    width: 83.3333333333%;
  }

  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .large-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    width: 91.6666666667%;
  }

  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .large-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .large-up-1 > .column,
  .large-up-1 > .columns {
    width: 100%;
    float: left;
  }

  .large-up-1 > .column:nth-of-type(1n),
  .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-1 > .column:nth-of-type(1n + 1),
  .large-up-1 > .columns:nth-of-type(1n + 1) {
    clear: both;
  }

  .large-up-1 > .column:last-child,
  .large-up-1 > .columns:last-child {
    float: left;
  }

  .large-up-2 > .column,
  .large-up-2 > .columns {
    width: 50%;
    float: left;
  }

  .large-up-2 > .column:nth-of-type(1n),
  .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-2 > .column:nth-of-type(2n + 1),
  .large-up-2 > .columns:nth-of-type(2n + 1) {
    clear: both;
  }

  .large-up-2 > .column:last-child,
  .large-up-2 > .columns:last-child {
    float: left;
  }

  .large-up-3 > .column,
  .large-up-3 > .columns {
    width: 33.3333333333%;
    float: left;
  }

  .large-up-3 > .column:nth-of-type(1n),
  .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-3 > .column:nth-of-type(3n + 1),
  .large-up-3 > .columns:nth-of-type(3n + 1) {
    clear: both;
  }

  .large-up-3 > .column:last-child,
  .large-up-3 > .columns:last-child {
    float: left;
  }

  .large-up-4 > .column,
  .large-up-4 > .columns {
    width: 25%;
    float: left;
  }

  .large-up-4 > .column:nth-of-type(1n),
  .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-4 > .column:nth-of-type(4n + 1),
  .large-up-4 > .columns:nth-of-type(4n + 1) {
    clear: both;
  }

  .large-up-4 > .column:last-child,
  .large-up-4 > .columns:last-child {
    float: left;
  }

  .large-up-5 > .column,
  .large-up-5 > .columns {
    width: 20%;
    float: left;
  }

  .large-up-5 > .column:nth-of-type(1n),
  .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-5 > .column:nth-of-type(5n + 1),
  .large-up-5 > .columns:nth-of-type(5n + 1) {
    clear: both;
  }

  .large-up-5 > .column:last-child,
  .large-up-5 > .columns:last-child {
    float: left;
  }

  .large-up-6 > .column,
  .large-up-6 > .columns {
    width: 16.6666666667%;
    float: left;
  }

  .large-up-6 > .column:nth-of-type(1n),
  .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-6 > .column:nth-of-type(6n + 1),
  .large-up-6 > .columns:nth-of-type(6n + 1) {
    clear: both;
  }

  .large-up-6 > .column:last-child,
  .large-up-6 > .columns:last-child {
    float: left;
  }

  .large-up-7 > .column,
  .large-up-7 > .columns {
    width: 14.2857142857%;
    float: left;
  }

  .large-up-7 > .column:nth-of-type(1n),
  .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-7 > .column:nth-of-type(7n + 1),
  .large-up-7 > .columns:nth-of-type(7n + 1) {
    clear: both;
  }

  .large-up-7 > .column:last-child,
  .large-up-7 > .columns:last-child {
    float: left;
  }

  .large-up-8 > .column,
  .large-up-8 > .columns {
    width: 12.5%;
    float: left;
  }

  .large-up-8 > .column:nth-of-type(1n),
  .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .large-up-8 > .column:nth-of-type(8n + 1),
  .large-up-8 > .columns:nth-of-type(8n + 1) {
    clear: both;
  }

  .large-up-8 > .column:last-child,
  .large-up-8 > .columns:last-child {
    float: left;
  }

  .large-collapse > .column,
  .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .expanded.row .large-collapse.row,
  .large-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }

  .large-uncollapse > .column,
  .large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .large-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .large-pull-0,
  .large-push-0,
  .large-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.3333333333%;
  }

  .xlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .xlarge-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .xlarge-offset-0 {
    margin-left: 0;
  }

  .xlarge-2 {
    width: 16.6666666667%;
  }

  .xlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .xlarge-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xlarge-3 {
    width: 25%;
  }

  .xlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .xlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xlarge-4 {
    width: 33.3333333333%;
  }

  .xlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .xlarge-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .xlarge-offset-3 {
    margin-left: 25%;
  }

  .xlarge-5 {
    width: 41.6666666667%;
  }

  .xlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .xlarge-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xlarge-6 {
    width: 50%;
  }

  .xlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .xlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xlarge-7 {
    width: 58.3333333333%;
  }

  .xlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .xlarge-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .xlarge-offset-6 {
    margin-left: 50%;
  }

  .xlarge-8 {
    width: 66.6666666667%;
  }

  .xlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .xlarge-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xlarge-9 {
    width: 75%;
  }

  .xlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .xlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xlarge-10 {
    width: 83.3333333333%;
  }

  .xlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .xlarge-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .xlarge-offset-9 {
    margin-left: 75%;
  }

  .xlarge-11 {
    width: 91.6666666667%;
  }

  .xlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .xlarge-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xlarge-12 {
    width: 100%;
  }

  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xlarge-up-1 > .column,
  .xlarge-up-1 > .columns {
    width: 100%;
    float: left;
  }

  .xlarge-up-1 > .column:nth-of-type(1n),
  .xlarge-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-1 > .column:nth-of-type(1n + 1),
  .xlarge-up-1 > .columns:nth-of-type(1n + 1) {
    clear: both;
  }

  .xlarge-up-1 > .column:last-child,
  .xlarge-up-1 > .columns:last-child {
    float: left;
  }

  .xlarge-up-2 > .column,
  .xlarge-up-2 > .columns {
    width: 50%;
    float: left;
  }

  .xlarge-up-2 > .column:nth-of-type(1n),
  .xlarge-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-2 > .column:nth-of-type(2n + 1),
  .xlarge-up-2 > .columns:nth-of-type(2n + 1) {
    clear: both;
  }

  .xlarge-up-2 > .column:last-child,
  .xlarge-up-2 > .columns:last-child {
    float: left;
  }

  .xlarge-up-3 > .column,
  .xlarge-up-3 > .columns {
    width: 33.3333333333%;
    float: left;
  }

  .xlarge-up-3 > .column:nth-of-type(1n),
  .xlarge-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-3 > .column:nth-of-type(3n + 1),
  .xlarge-up-3 > .columns:nth-of-type(3n + 1) {
    clear: both;
  }

  .xlarge-up-3 > .column:last-child,
  .xlarge-up-3 > .columns:last-child {
    float: left;
  }

  .xlarge-up-4 > .column,
  .xlarge-up-4 > .columns {
    width: 25%;
    float: left;
  }

  .xlarge-up-4 > .column:nth-of-type(1n),
  .xlarge-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-4 > .column:nth-of-type(4n + 1),
  .xlarge-up-4 > .columns:nth-of-type(4n + 1) {
    clear: both;
  }

  .xlarge-up-4 > .column:last-child,
  .xlarge-up-4 > .columns:last-child {
    float: left;
  }

  .xlarge-up-5 > .column,
  .xlarge-up-5 > .columns {
    width: 20%;
    float: left;
  }

  .xlarge-up-5 > .column:nth-of-type(1n),
  .xlarge-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-5 > .column:nth-of-type(5n + 1),
  .xlarge-up-5 > .columns:nth-of-type(5n + 1) {
    clear: both;
  }

  .xlarge-up-5 > .column:last-child,
  .xlarge-up-5 > .columns:last-child {
    float: left;
  }

  .xlarge-up-6 > .column,
  .xlarge-up-6 > .columns {
    width: 16.6666666667%;
    float: left;
  }

  .xlarge-up-6 > .column:nth-of-type(1n),
  .xlarge-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-6 > .column:nth-of-type(6n + 1),
  .xlarge-up-6 > .columns:nth-of-type(6n + 1) {
    clear: both;
  }

  .xlarge-up-6 > .column:last-child,
  .xlarge-up-6 > .columns:last-child {
    float: left;
  }

  .xlarge-up-7 > .column,
  .xlarge-up-7 > .columns {
    width: 14.2857142857%;
    float: left;
  }

  .xlarge-up-7 > .column:nth-of-type(1n),
  .xlarge-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-7 > .column:nth-of-type(7n + 1),
  .xlarge-up-7 > .columns:nth-of-type(7n + 1) {
    clear: both;
  }

  .xlarge-up-7 > .column:last-child,
  .xlarge-up-7 > .columns:last-child {
    float: left;
  }

  .xlarge-up-8 > .column,
  .xlarge-up-8 > .columns {
    width: 12.5%;
    float: left;
  }

  .xlarge-up-8 > .column:nth-of-type(1n),
  .xlarge-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xlarge-up-8 > .column:nth-of-type(8n + 1),
  .xlarge-up-8 > .columns:nth-of-type(8n + 1) {
    clear: both;
  }

  .xlarge-up-8 > .column:last-child,
  .xlarge-up-8 > .columns:last-child {
    float: left;
  }

  .xlarge-collapse > .column,
  .xlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .expanded.row .xlarge-collapse.row,
  .xlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }

  .xlarge-uncollapse > .column,
  .xlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .xlarge-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .xlarge-pull-0,
  .xlarge-push-0,
  .xlarge-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    width: 8.3333333333%;
  }

  .xxlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .xxlarge-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .xxlarge-offset-0 {
    margin-left: 0;
  }

  .xxlarge-2 {
    width: 16.6666666667%;
  }

  .xxlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .xxlarge-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xxlarge-3 {
    width: 25%;
  }

  .xxlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .xxlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xxlarge-4 {
    width: 33.3333333333%;
  }

  .xxlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .xxlarge-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .xxlarge-offset-3 {
    margin-left: 25%;
  }

  .xxlarge-5 {
    width: 41.6666666667%;
  }

  .xxlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .xxlarge-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xxlarge-6 {
    width: 50%;
  }

  .xxlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .xxlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xxlarge-7 {
    width: 58.3333333333%;
  }

  .xxlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .xxlarge-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .xxlarge-offset-6 {
    margin-left: 50%;
  }

  .xxlarge-8 {
    width: 66.6666666667%;
  }

  .xxlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .xxlarge-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xxlarge-9 {
    width: 75%;
  }

  .xxlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .xxlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xxlarge-10 {
    width: 83.3333333333%;
  }

  .xxlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .xxlarge-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .xxlarge-offset-9 {
    margin-left: 75%;
  }

  .xxlarge-11 {
    width: 91.6666666667%;
  }

  .xxlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .xxlarge-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xxlarge-12 {
    width: 100%;
  }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xxlarge-up-1 > .column,
  .xxlarge-up-1 > .columns {
    width: 100%;
    float: left;
  }

  .xxlarge-up-1 > .column:nth-of-type(1n),
  .xxlarge-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-1 > .column:nth-of-type(1n + 1),
  .xxlarge-up-1 > .columns:nth-of-type(1n + 1) {
    clear: both;
  }

  .xxlarge-up-1 > .column:last-child,
  .xxlarge-up-1 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-2 > .column,
  .xxlarge-up-2 > .columns {
    width: 50%;
    float: left;
  }

  .xxlarge-up-2 > .column:nth-of-type(1n),
  .xxlarge-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-2 > .column:nth-of-type(2n + 1),
  .xxlarge-up-2 > .columns:nth-of-type(2n + 1) {
    clear: both;
  }

  .xxlarge-up-2 > .column:last-child,
  .xxlarge-up-2 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-3 > .column,
  .xxlarge-up-3 > .columns {
    width: 33.3333333333%;
    float: left;
  }

  .xxlarge-up-3 > .column:nth-of-type(1n),
  .xxlarge-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-3 > .column:nth-of-type(3n + 1),
  .xxlarge-up-3 > .columns:nth-of-type(3n + 1) {
    clear: both;
  }

  .xxlarge-up-3 > .column:last-child,
  .xxlarge-up-3 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-4 > .column,
  .xxlarge-up-4 > .columns {
    width: 25%;
    float: left;
  }

  .xxlarge-up-4 > .column:nth-of-type(1n),
  .xxlarge-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-4 > .column:nth-of-type(4n + 1),
  .xxlarge-up-4 > .columns:nth-of-type(4n + 1) {
    clear: both;
  }

  .xxlarge-up-4 > .column:last-child,
  .xxlarge-up-4 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-5 > .column,
  .xxlarge-up-5 > .columns {
    width: 20%;
    float: left;
  }

  .xxlarge-up-5 > .column:nth-of-type(1n),
  .xxlarge-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-5 > .column:nth-of-type(5n + 1),
  .xxlarge-up-5 > .columns:nth-of-type(5n + 1) {
    clear: both;
  }

  .xxlarge-up-5 > .column:last-child,
  .xxlarge-up-5 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-6 > .column,
  .xxlarge-up-6 > .columns {
    width: 16.6666666667%;
    float: left;
  }

  .xxlarge-up-6 > .column:nth-of-type(1n),
  .xxlarge-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-6 > .column:nth-of-type(6n + 1),
  .xxlarge-up-6 > .columns:nth-of-type(6n + 1) {
    clear: both;
  }

  .xxlarge-up-6 > .column:last-child,
  .xxlarge-up-6 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-7 > .column,
  .xxlarge-up-7 > .columns {
    width: 14.2857142857%;
    float: left;
  }

  .xxlarge-up-7 > .column:nth-of-type(1n),
  .xxlarge-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-7 > .column:nth-of-type(7n + 1),
  .xxlarge-up-7 > .columns:nth-of-type(7n + 1) {
    clear: both;
  }

  .xxlarge-up-7 > .column:last-child,
  .xxlarge-up-7 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-8 > .column,
  .xxlarge-up-8 > .columns {
    width: 12.5%;
    float: left;
  }

  .xxlarge-up-8 > .column:nth-of-type(1n),
  .xxlarge-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }

  .xxlarge-up-8 > .column:nth-of-type(8n + 1),
  .xxlarge-up-8 > .columns:nth-of-type(8n + 1) {
    clear: both;
  }

  .xxlarge-up-8 > .column:last-child,
  .xxlarge-up-8 > .columns:last-child {
    float: left;
  }

  .xxlarge-collapse > .column,
  .xxlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .expanded.row .xxlarge-collapse.row,
  .xxlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }

  .xxlarge-uncollapse > .column,
  .xxlarge-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  .xxlarge-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  .xxlarge-pull-0,
  .xxlarge-push-0,
  .xxlarge-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
}
