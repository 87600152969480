html,
body {
  height: 100%;
  margin: 0;
}

.site-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
}

.content .presentation-header {
  display: none;
}

.content .presentation-header.show {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 150px;
}

.content .presentation-header.show img {
  width: 100vw;
  height: auto;
  object-fit: cover;
}

.content .full-screen-presentation {
  display: none;
}

.content .full-screen-presentation.show {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 150px;
  width: 100vw;
  margin-left: -21vw;
}

.content .full-screen-presentation.show img {
  width: 100vw;
  height: auto;
  object-fit: cover;
}

.full-screen-img {
  width: 100vw;
  height: auto;
  max-height: 100vh;
  margin-left: 20.7vw;
}

@media (max-width: 768px) {
  .full-screen-img {
    margin-left: 29vw;
  }
}

.content .intro {
  font-size: small;
  font-style: italic;
}

footer {
  margin-top: auto;
}

body {
  margin: 0;
  background-color: #000;
  font-family: 'Open Sans', sans-serif, arial, serif;
  font-size: 90%;
}

a:active,
a:hover {
  outline-width: 0;
}

a:visited,
a:link {
  color: #ff439a;
  text-decoration: none;
}

.footer-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  line-height: 1.4;
}

html.menu-opened {
  overflow: hidden;
}

h1,
h2,
h3 {
  line-height: 1;
  margin: 0 0 0.9375rem;
  color: #102e44;
}

h1 {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.3rem;
  }
}

h2 {
  font-size: 1.25rem;
}

@media (max-width: 767px) {
  h2 {
    font-size: 1.15rem;
  }
}

h3 {
  font-size: 1.15rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1.07rem;
  }
}

h4 {
  font-size: 1.07rem;
}

@media (max-width: 767px) {
  h4 {
    font-size: 0.9rem;
  }
}

img {
  max-width: 100%;
}

.table {
  align-content: center;
  color: #000;
}

.badge {
  height: auto;
  width: 80px;
}

.portret {
  height: auto;
  width: 250px;
}

.warning {
  background-color: rgb(255, 216, 201);
  padding: 15px;
  border-left: 6px solid red;
  margin-bottom: 20px;
}

.info {
  background-color: rgb(228, 251, 255);
  padding: 15px;
  border-left: 6px solid rgb(87, 188, 255);
  margin-bottom: 20px;
}

.main-title-syntax {
  color: #ff439a;
}

.main-title-nebula {
  color: #102e44;
}

.main-title-syntax,
.main-title-nebula {
  display: inline;
}
